/* Home */
.headerText {
    font-size: 40px;
    padding: 100px 0 40px 0;
    font-weight: bolder;
}

.bodyText {
    font-size: 24px;
    padding-bottom: 40px;
}

.categoryTitle {
    font-size: 24px;
    font-weight: bolder;
    padding-top: 60px;
}
/* End of Home */

/* Project Card */
.projectHeading {
    font-size: 40px;
    font-weight: bolder;
    padding-top: 100px;
}

.projectGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 40px;
}

.projectCard {
    width: 50%; 
    height: 500px;
    margin-bottom: 80px;
    transition: transform 0.5s ease-in-out; 
}

.projectCard:hover {
    transform: scale(0.95);
    opacity: .7;
}

.projectLink {
    text-decoration: none;
    color: #FFFFFF;
}

.projectImage {
    height: 350px;
}

.projectTitle {
    font-size: 20px;
    font-weight: bold;
    padding-top: 24px;
}

.projectDesc {
    color: #A5A5A5;
    padding-top: 16px;
    font-size: 18px;
    width: 500px;
}

.projectTag {
    background-color: #616E7B;
    color: #FFF;
    border-radius: 1rem;
    padding: 6px 18px;
    margin: 16px 8px 0 0;
    display: inline-block; 
    font-size: 12px;
}
/* End of Project Card */


/* About */
.aboutHeading {
    font-weight: bold;
    padding-bottom: 24px;
}

.aboutBody {
    font-size: 16px;
    padding-bottom: 12px;
}

.aboutCard {
    padding: 60px 0 80px 0;
}

.aboutImg img {
    width: 500px;
    /* height: 500px; */
}

.socialIcons a {
    color: #FFFFFF;
    padding: 16px 60px 0 0;
    font-size: 24px;
}

.socialIcons a:last-child {
    font-size: 20px;
}
/* End of About */


/* Project Details */
.pageHeading {
    font-size: 24px;
    letter-spacing: 4px;
    font-weight: bold;
}

.pageDescription {
    font-weight: 200;
    font-size: 16px;
}

.border-bottom {
    color: #FFFFFF;
    opacity: .5;
    padding: 24px 0;
}

.boldText {
    font-weight: bolder;
    font-size: 18px;
}

.large-text {
    font-weight: bolder;
    font-size: 32px;
    padding: 20px 0 16px 0;
}

ul li {
    font-size: 16px;
    padding: 12px 0;
}

.previousBtn i {
    margin-right: 20px;
    font-size: 18px;
}

.previousBtn a {
    text-decoration: none;
    color: #FFFFFF;
}

.nextBtn i {
    margin-left: 20px;
    font-size: 18px;
}

.nextBtn a {
    text-decoration: none;
    color: #FFFFFF;
}

.grey {
    color: #636363;
}

.gifCard img {
    height: 500px;
}

.text-link {
    color: #50B9FF;
    text-decoration: underline;
}

.small-gif img {
    width: 200px;
    height: 400px;
}

.groupHead {
    font-size: 24px;
    font-weight: bolder;
}

.small-width {
    padding: 0 100px;
}

.subtitle-text {
    font-size: 12px;
    color: #818181;
}

.theme-card {
    width: 300px;
}

.theme-card h2 {
    font-size: 24px;
    font-weight: bolder;
    border-bottom: 10px solid #2851C4;
}

.theme-card ul li {
    padding-bottom: 32px;
}

.testing-card {
    padding-bottom: 80px;
}

/* .testing-card img {
    width: 600px;
    height: 400px;
} */
/* End of Project Details */

/* Contact */
.fullwidth {
    height: 100vh;
    padding-top: 100px;
}

.contact-group {
    padding: 80px 20px 20px 0;
}

.contact-group a {
    font-size: 60px;
    text-decoration: none;
    color: #FFF;
}

.contact-group a:hover {
    text-decoration: underline;
    color: #2E56E4;
}

.social-group {
    padding: 250px 24px 0 24px;
}

.social-group a {
    margin-right: 80px;
}

.social-group i {
    color: #FFFFFF;
    font-size: 24px;
}

.social-group i:hover {
    color: #2E56E4;
}
/* End of Contact */


/* Illustration */
.illustrationImages * {
    padding-bottom: 40px;
}
/* End of Illustration */