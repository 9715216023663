.App {
  background-color: #131313;
  height: 100%;
  width: 100%;
  background-color: #131313;
  color: #fff;
  position: relative;
  font-family: 'EuclidCircular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem
}

img {
  max-width: 100%;
}