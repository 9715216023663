nav {
    background-color: #131313;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 5rem;
    position: sticky;
    top: 0;
}

nav ul {
    display: flex;
}

nav ul li {
    list-style: none;
}

nav ul li a {
    color: #FFF;
    text-decoration: none;
    padding: 0 1rem;
    margin: 0 1rem;
}

.active,
nav ul li a:hover {
    text-decoration: line-through;
}

nav .menu {
    display: none;
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 2rem;
}

nav .menu span {
    height: 0.2rem;
    width: 100%;
    background-color: #FFF;
    border-radius: 0.1rem;
}

.logo img {
    width: 2rem;
}

@media (max-width: 480px) {
    nav .menu {
        display: flex;
    }

    nav {
        flex-direction: column;
        align-items: start;
    }

    nav ul {
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.25rem;
    }

    nav ul.open {
        display: flex;
    }

    nav ul li {
        width: 100%;
        text-align: center;
    }

    nav ul li a {
        margin: 0.2rem 0.5rem;
    }
}